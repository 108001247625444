.App {
    text-align: center;
    position: relative;
    color: #0C0F0A;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.App-header {
  background-color: grey;
  margin-bottom: 50px;
}

.App-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-header-title-logo {
    flex-grow: .1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-header-title-logo>img {
    height: 80px;
}

.App-header-title h1 {
    font-size: 4em;
    font-family: 'Courgette', cursive;
    color: white;
    margin: 10px 0;
    flex-grow: .9;
}

.separator{
    height: 3px;
    width: 100%;
    background-color: #41ead4;
}
.table{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}
.day{
    width: 15%;
    height: 70vh;
    padding-top: 20px;
    margin-left: 20px;
    margin-right:20px;
    list-style: none;
}
.day ul{
    padding-left: 0;
}
.dayTitle{
    height: 50px;
    color: #41ead4;
    font-size: 35px;
    font-family: 'Satisfy', cursive;
    margin-bottom: 50px;
}
.mealBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 1000;
    box-shadow: 5px 5px 3px #F1F1F1;
    margin-bottom: 10px;
}
.mealDetails{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.mealWho{
    font-family: 'Courgette', cursive;
}
.fa-trash:hover{
    cursor: pointer;
    color: #FF206E;
}
.btnWrapper{
    display: flex;
}
.btn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.5em;
    margin: 20px;
    line-height: 30px;
    color: grey;
}
.btn svg{
    color: grey;
    font-size: 1.8em;
}
.btn:hover{
    cursor: pointer;
}
.add{
    background-color: white;
    width: 40px;
    height: 48px;
    top: 50%;
}
.add:hover{
   background: radial-gradient(#41ead4 1%, white 99%);
}
.idea{
    color: #0C0F0A;
    background-color: white;
    width: 40px;
    height: 48px;
    top: 38%;
}
.idea:hover{
    background: radial-gradient(#41ead4 1%, white 99%);
}

.recipes{
    background-color: white;
    top: 62%;
    width: 40px;
    height: 48px;
}

.recipes:hover{
    background: radial-gradient(#41ead4 3%, white 97%);
}

.add2{
    color: #41EAD4;
    background-color: white;
    border: 2px solid #41EAD4;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.5em;
    margin: 20px;
    line-height: 30px;
    cursor: pointer;
}
.add2:hover{
    color: white;
    background-color: #41EAD4;
}
#when{
    cursor: pointer;
}
.cancel{
    color: #FF206E;
    background-color: white;
    border: 1px solid #FF206E;
    top: 53%;
    left: 58%;
}
.cancel:hover{
    color: white;
    background-color: #FF206E;
}
.modal{
    display: flex;
    position: absolute;
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    opacity: 0;
    justify-content: center;
    align-items: center;
    background: none;
    z-index: -1;
}
.modal.mOpen{
    opacity: 1;
    height: 100vh;
    background: rgba(255,255,255,0.8);
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    z-index: 100;
}
#popUpAdd, #popUpMeal, #popUpRecipes{
    display: none;
    flex-direction: column;
    background-color: white;
    box-shadow: 5px 5px 8px #888888;
    opacity: 0;
    -webkit-transition: left  1s ease-out;
    -moz-transition: left 1s  ease-out;
    -o-transition: left 1s  ease-out;
    transition: left 1s  ease-out;
    height: auto;
    min-height: 300px;
}
#popUpMeal, #popUpRecipes{
    align-self: flex-start;
}
#popUpAdd, #popUpRecipes{
    justify-content: flex-start;
    align-items: center;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    width: 100%;
    border-bottom: 3px solid #41EAD4;
}
.header h2{
    margin: 0px;
    font-family: 'Courgette', cursive;
    font-size: 3em;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.closeBtn{
    margin-right: 20px;
    font-weight: bold;
    z-index: 1000000;
    font-size: 1.7em;
    margin-top: 10px;
    border-radius: 50% 50%;
    align-self: flex-end;
}
.closeBtn:hover{
    cursor: pointer;
    background: radial-gradient(#41ead4 3%, white 97%);
}
#popUpMeal #mealIdeasTable{
    margin-bottom:20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: left;
}
.ideaMeal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 1em;
    margin: 10px;
    border-left: 2px solid white;
}
.ideaMeal:hover{
    border-left: 2px solid #41EAD4;
    box-shadow: 10px 10px 10px #f1f1f1;
}

.popForm{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
form{
    display: flex;
    flex-direction: column;
    width: 50%;
}
form input{
    margin:10px;
    width: 300px;
    height: 20px;
    border: none;
    border-bottom: 1px solid #0C0F0A;
    outline: none;
}
form select{
    margin:10px;
    width: 300px;
    height: 20px;
    border: none;
    border-bottom: 1px solid #0C0F0A;
    outline: none;
}
form input:focus{
    border-bottom: 1px solid #41EAD4;
    outline: none;
}

.ideaMeal h4{
    margin-bottom: 0px;
    margin-top: 5px;
    text-align: left;
    font-size: 1.5em;
}
.bloc{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}
.infoBloc{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-left: 10px;
    width: 20%;
    font-weight: bold;
}
.restoBloc{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.type{
    font-style: italic;
    font-family: 'Courgette', cursive;
    font-size: 1.1em;
    margin-bottom: 0;
}
.rating{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;
}
.rating p {
    margin-left: 7px;
}
.map{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 20%;
    margin-right: 20px;
}
.map a{
    margin-bottom: 23px;
    text-decoration: none;
    color: #0C0F0A;
}
.map a:hover{
    cursor: pointer;
    color: #41EAD4;
}
.mapGoogle{
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 700px;
    top: 6%;
}
.sortIdeasBloc{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    padding-top: 20px;
}

.sortIdeasBloc .instruction{
    margin:0;
    width: 100%;
}

.sortIdeasBloc .svg-inline--fa{
    margin-right: 10px;
}

.sortIdeasBloc label{
    margin: 0 20px;
    cursor: pointer;
}

input[type="radio"], input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
label {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 10px;
  line-height: 36px;
  cursor: pointer;
}
label::before {
  content: " ";
  position: absolute;
  top: 6px;
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid #41EAD4;
  border-radius: 4px;
}
input[type="radio"] + label::before {
  border-radius: 18px;
}

input[type="checkbox"] + label::before {
  border-radius: 18px;
}

input[type="radio"]:checked + label {
  padding-left: 10px;
}

input[type="checkbox"]:checked + label {
  padding-left: 10px;
  color: #fff;
}

input[type="radio"]:checked + label::before {
  top: 0;
  width: 100%;
  height: 96%;
}

input[type="checkbox"]:checked + label::before {
  top: 0;
  width: 100%;
  height: 100%;
  background: #41EAD4;
}
/* Transition */
label,
label::before {
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
#inputRecipes{
    display: flex;
    flex-direction: row;
    margin: 30px auto;
}
#inputRecipes input{
    width: 250px;
    margin: 0 50px;
}
#filters{
    display: flex;
    flex-direction: row;
    margin: 10px auto;
}
#filters div{
    width: 48%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    display: none;
}
#filters p{
    font-family: 'Courgette', cursive;
    font-size: 1.4em;
}
.submit{
    font-size: 2.1em;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-color: #41EAD4;
    cursor: pointer;
    position: relative;
}
.submit::before {
    opacity: 0;
    position: absolute;
    content: '';
    transition: 500ms all ease;
    transition-delay: 100ms;
    background-color: #41EAD4;
    top: 0px;
    left: 0;
    height: 55px;
    width: 0px;
    line-height: 50px;
    z-index: 1;
    border-radius: 30px;
}
.submit::after {
    position: absolute;
    content: attr(data-txt);
    transition: 350ms all ease;
    font-family: 'Courgette', cursive;
    top: -5px;
    left: 20px;
    height: 50px;
    width: 110px;
    line-height: 50px;
    padding: 5px;
    z-index: 1000;
    color: #0C0F0A;
}
.submit:hover::before{
    opacity: 0.9;
    width: 130px;
}
.submit:hover::after {
    left: 5px;
    color: white;
}

#recipeList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    
}
.recipeBlock{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 47%;
    margin: 10px;
}

.recipeDetails{
    display: flex;
    flex-direction: column;
    width: 60%;
    text-align: left;
    padding: 0px;
}
.recipeDetails p{
    margin: 0px 0 10px 0;
}
.fa-external-link-square-alt{
    margin-left: 10px;
    font-size: 1.5em;
    color: grey;
}
.fa-external-link-square-alt:hover{
    background: radial-gradient(#41ead4 1%, white 99%);
}
.App-footer{
    height: 100px;
    width: 100%;
}
.App-footer p {
    font-style: italic;
    color: grey;
}
.App-footer a {
    border-bottom: 1px solid #41ead4;
    color: grey;
    text-decoration: none;
    transition: all 200ms ease;
}
.App-footer a:hover {
    border-bottom: 2px solid #41ead4;
    color: grey;
}
.App-footer .fa-star{
    margin-right: 5px;
    color: #41EAD4;
}