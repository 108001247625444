@media only screen and (max-width: 600px){
    .table{
        padding: 0;
        margin: 10px auto;
    }
    .day{
        width: 90%;
        height: auto;
    }
    .btnWrapper{
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        right: 0;
        top: 7%;
        width: 100%;
    }
    #popUpMeal, #popUpRecipes, #popUpAdd{
        width: 92%;
    }
    #popUpAdd{
        height: 400px;
        align-self: flex-start;
    }
    #popUpAdd .popForm{
        flex-direction: column;
    }
    #popUpAdd .popForm form{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    #popUpAdd form input, #popUpAdd form select{
        width: 90%;
        margin: 10px 0;
    }
    .add2{
        display: block;
        top: 0;
        left: 0;
    }
    .header h2{
        padding: 5px;    
        height: 120px;
    }
    #mealIdeasTable{
        padding-left: 0;
    }
    .ideaMeal{
        width: 100%;
    }
    .ideaMeal h4{
        margin-bottom: 15px;
    }
    #popUpRecipes .popForm{
        flex-direction: column;
        justify-content: center;
    }
    #popUpRecipes .popForm form{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    #popUpRecipes .submit{
        align-self: center;
        margin: 15px 0px 15px -70px;
    }
    #inputRecipes input{
        margin: 0;
    }
    #recipeList{
        padding: 0;
    }
    .recipeBlock, .recipeDetails{
        width: 100%;
    }
    .recipeBlock{
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 601px) and (max-width:767px){
    .table{
        padding: 0;
        margin: 10px auto;
        align-items: flex-start;
    }
    .day{
        width: 40%;
        height: auto;
    }
    .btnWrapper{
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        right: 0;
        top: 8%;
        width: 100%;
    }
    .header h2{  
        font-size: 3em;
        height: 135px;
    }
    .ideaMeal{
        width: 100%;
    }
    #popUpAdd{
        width: 500px;
        height: 400px;
        align-self: center;
    }
    #popUpMeal, #popUpRecipes{
        width: 92%;
    }
    #popUpAdd .popForm{
        flex-direction: column;
    }
    #popUpAdd .popForm form{
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    #popUpAdd form input, #popUpAdd form select{
        width: 90%;
        margin: 10px 0;
    }
    #popUpRecipes .submit{
        align-self: none;
        margin: 0% 0px 0px 10%;
    }
    .add2{
        display: block;
        top: 0;
        left: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width:991px){
    .table{
        padding: 0;
        margin: auto;
        align-items: flex-start;
        flex-wrap: wrap;
        height: auto;
        margin-bottom: 50px;
    }
    .day{
        width: 40%;
        height: auto;
    }
    .btnWrapper{
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        right: 0;
        top: 8%;
        width: 100%;
    }
    .header h2{
        height: 100px;
    }
    .ideaMeal{
        width: 90%;
    }
    #popUpAdd{
        width: 600px;
        height: 300px;
        align-self: center;
    }
    #popUpMeal, #popUpRecipes{
        width: 85%;
    }
    #popUpAdd .popForm{
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    #popUpAdd .popForm form{
        width: 50%;
        justify-content: flex-start;
        align-items: center;
    }
    #popUpRecipes .submit{
        align-self: flex-start;
        margin: 20px 0px 0px 10%;
    }
    .add2{
        display: block;
        top: 49.5%;
        left: 58%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px){
    .table{
        padding: 0;
        margin: auto;
        align-items: flex-start;
        flex-wrap: wrap;
        height: auto;
        margin-bottom: 50px;
    }
    .day{
        width: 25%;
        height: auto;
    }
    .btnWrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 20px;
        top: 30%;
        width: 40px;
    }
    .btn{
        width: 40px;
    }
    .ideaMeal{
        width: 90%;
    }
    #popUpAdd{
        width: 600px;
        height: 300px;
        align-self: center;
    }
    #popUpMeal, #popUpRecipes{
        width: 80%;
    }
    #popUpAdd .popForm{
        flex-direction: row;
        justify-content: center;
    }
    #popUpAdd .popForm form{
        width: 50%;
        justify-content: flex-start;
        align-items: center;
    }
    #popUpRecipes .submit{
        align-self: flex-start;
        margin: 20px 0px 0px 10%;
    }
    .add2{
        display: block;
        top: 49.5%;
        left: 58%;
    }
}

@media only screen and (min-width: 1200px){
    .table{
        padding: 0;
        margin: auto;
        align-items: flex-start;
        flex-wrap: nowrap;
        height: auto;
        margin-bottom: 50px;
    }
    .day{
        width: 15%;
        height: auto;
    }
    .btnWrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 20px;
        top: 30%;
        width: 40px;
    }
    .btn{
        width: 40px;
    }
    .ideaMeal{
        width: 45%;
    }
    #popUpAdd{
        width: 600px;
        height: 300px;
        align-self: center;
    }
    #popUpMeal, #popUpRecipes{
        width: 80%;
    }
    #popUpAdd .popForm{
        flex-direction: row;
        justify-content: center;
    }
    #popUpAdd .popForm form{
        width: 50%;
        justify-content: flex-start;
        align-items: center;
    }
    #popUpRecipes .submit{
        align-self: flex-start;
        margin: 20px 0px 0px 10%;
    }
    .add2{
        display: block;
        top: 49.5%;
        left: 58%;
    }
}